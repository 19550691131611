<template>
  <div>
    <div>
          <div>
            <strong>{{entity.name}}</strong><b-button class="mx-2" variant="outline-primary" size="sm" v-if="permission.add" @click="modalShow = !modalShow" >edit</b-button>
          </div>
          <div>
            {{entity.description}}
          </div>
    </div>
      <b-modal v-model="modalShow" scrollable size="xl" ok-only>
        <div class="my-5">
          <property-input
            :idProp="entity.id"
            :fieldProp="'name'"
            :nameDisplayProp="'Name'"
            :modelProp="'requirement'"
            :valueProp="entity.name"
          ></property-input>
        </div>
        <div class="my-5">
          <property-text
            :idProp="entity.id"
            :htmlProp="false"
            :fieldProp="'description'"
            :nameDisplayProp="'Description'"
            :modelProp="'requirement'"
            :valueProp="entity.description"
          ></property-text>
        </div>
      </b-modal>
  </div>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import PropertyInput from '@/components/sql/PropertyInput.vue'
import PropertyText from '@/components/sql/PropertyText.vue'

export default {
  name: 'Requirement',
  components: {
    PropertyInput,
    PropertyText
  },
  props: ['entity'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permission.read = ac.can(this.user.acgroups).readAny('regulation').granted
    this.permission.add = ac.can(this.user.acgroups).createAny('regulation').granted
  },
  data () {
    return {
      modalShow: false,
      moment: moment,
      permission: {
        add: false,
        read: false
      },
      usersLoaded: [],
      usersLoading: false,
      usersMatched: [],
      userSearchInput: ''
    }
  },
  methods: {
    regulationEdit: function () {
      this.show.add = false
      this.show.all = false
      this.show.users = true
    },
    regulationEditDone: function (id) {
      this.show.add = false
      this.show.all = true
      this.show.users = false
    }
  }
}
</script>

<style>
</style>
